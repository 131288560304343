import React from "react";
import HeaderBar from "../../components/header-bar/HeaderBar";


export default function ProjectOne() {


    return (
        <>
            <HeaderBar />
            <div>One</div>
        </>
        
    )
}